import '../sass/main.scss';

import $ from 'jquery';
window.$ = window.jQuery = $;
import 'slick-carousel'; 
import LazyLoad from 'vanilla-lazyload';

var siteGlobal = {
  initReady: function () {
  },
  initLoad: function () {
  	this.lazyLoad();
    this.menuInit();
    this.cosaSlider();
    this.quantitaInit();
    this.formScrollInit();
  },
  initScroll: function(){
  },
  lazyLoad: function(){
    var ll = new LazyLoad({
        elements_selector: '.lazy',
        use_native: true
    })
  },
  menuInit: function(){
    $('.header__hamburger').on('click', function(){
      $('.header__hamburger, .header, .header__menu').toggleClass('opened');
    })
    
    $('.header .menu-item-has-children > a').addClass('no-smoothState');
    
    if ($(window).outerWidth(true) > 767) {
      $('.header .nav > .menu-item-has-children > a').on('click', function(event){
        $('.header').toggleClass('expanded');

        event.preventDefault();
        $(this).parent().toggleClass('active');
        
        var sub = $(this).siblings('ul');
        sub.toggleClass('active');

      })
    } else {
      $('.menu-item-162 > a').on('click', function(event){
        event.preventDefault();
        $(this).parent().toggleClass('active'); 
        var sub = $(this).siblings('ul');
        sub.slideToggle(200);
      });
    }


    if ($(window).outerWidth(true) > 767) {
      $('.header .sub-menu > .menu-item-has-children > a').on('click', function(event){
        event.preventDefault();
        $(this).parent().toggleClass('active');
        
        var sub = $(this).siblings('ul');

        sub.toggleClass('active');
        // if ($(window).outerWidth(true) > 767) {
        //   sub.toggleClass('active');
        // } else {
        //   sub.slideToggle(200);
        // }
      })
    }

  },
  cosaSlider: function(){
    if ($('.blockGal').length) { 
      $('.blockGal').not('.slick-initialized').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        autoplay: true,
        autoplaySpeed: 4500,
        speed: 800,
        infinite: true
      });
    }

    $('.blockGal').on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
          var i = (currentSlide ? currentSlide : 0) + 1;
          $('.pagingInfo').text(i + '/' + slick.slideCount);
      });
  },
  formScrollInit: function(){
    $('.prodotto__btn').on('click', function(){
      event.preventDefault();
      $('html, body').animate({
        scrollTop: $('#richiesta-prod').offset().top - 100
      }, 800);
    })
  },
  quantitaInit: function(){
    $('#cqy').on('change keyup', function(){
      var value = $('#cqy').val();
      $('[name="quantita"]').val(value);
    })
  },
  rebuildAllEvents: function(){
    this.initLoad();
  },
};

global.siteGlobal = siteGlobal;


(function () {
  siteGlobal.initReady();
  $(window).on('load', function() {
    $('#preloader').addClass('loaded');
    siteGlobal.initLoad();
  });

  $(window).on('scroll', function(){
    siteGlobal.initScroll();
  })
}());